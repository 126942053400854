import './css/Pricing.css';
import PricingImage1 from '../../assets/pricing1.svg'
import PricingImage2 from '../../assets/pricing2.svg'
import PricingImage3 from '../../assets/pricing3.svg'
import PricingImage4 from '../../assets/pricing4.svg'
import PricingImage5 from '../../assets/pricing5.png'
import PricingCheck from '../../assets/check.svg'
import PricingMan from '../../assets/pricingMan.png'


const Pricing = () => {
    return <div >
        <div style={{ width: "100%", height: "630px" }}>
            <div className='flexDiv flexColumn' >
                <br /><br /><br /><br /><br />
                <span className="spanTitle">Get Your New York Real Estate</span>
                <span className="spanTitle">Estate License Now</span>
                <p className='pStyle'>The fastest and most affordable way to become a licensedreal estate agent in New York!</p>
                <button className='get-started'>
                    <span >GET STARTED</span>
                </button>
            </div>
        </div>
        <div className='flexDiv' style={{ width: "100%", height: "170px", background: "#0B3028" }}>
            <div className='flexRow' style={{ display: "flex" }}>
                <div className='flexDiv flexRow' style={{ margin: '0 30px' }}>
                    <img src={PricingImage1} />
                    <span className='span2'>Lorem Ipsum Dolor Sit Amet</span>
                </div>
                <div className='flexDiv flexRow' style={{ margin: '0 30px' }}>
                    <img src={PricingImage2} />
                    <span className='span2'>Lorem Ipsum Dolor Sit Amet</span>
                </div>
                <div className='flexDiv flexRow' style={{ margin: '0 30px' }}>
                    <img src={PricingImage3} />
                    <span className='span2'>Lorem Ipsum Dolor Sit Amet</span>
                </div>
                <div className='flexDiv flexRow' style={{ margin: '0 30px' }}>
                    <img src={PricingImage4} />
                    <span className='span2'>Lorem Ipsum Dolor Sit Amet</span>
                </div>
            </div>
        </div>
        <div className='flexDiv' style={{ width: "100%", height: "900px" }}>
            <div className='flexDiv flexRow' style={{ width: "80%", background: "#ffffff" }}>
                <div style={{ width: "50%" }}>
                    <img style={{ width: "704px", height: "715px", marginLeft: "auto"}} src={PricingImage5} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "50%", paddingLeft: "60px", paddingRight: "120px" }}>
                    <span className='group3-span1' style={{ color: "#535C5E"}}>new york</span>
                    <span className='group3-span2'>Master the Missouri real estate licensing exam with our expert instruction.</span>
                </div>
            </div>
        </div>

        <div className='group4-div1'>
            <div className='flexDiv flexColumn' style={{ width: "90%" }}>
                <div className='flexColumn' style={{ display: "flex", margin: "30px" }}>
                    <span className='group4-span1'>pricing</span>
                    <span className='group4-span2'>What We Offer</span>
                </div>
                <br />
                <div className='flexDiv' style={{ width: "100%" }}>
                    <div className='group4-div2'>
                        <div className='group4-div3'>
                            <div className='group4-div3'>
                                <span className='group4-span3'>Lorem Ipsum</span>
                                <span className='group4-span4'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</span>
                                <div className='group4-div4'>
                                    <span className='group4-span5'>$79.99</span>
                                    <button className='group4-button1'>
                                        <span className='group4-span6'>ENROLL NOW</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ width: "90%", marginTop: "20px" }}>
                                <div style={{ display: "flex", marginBottom: "15px" }}>
                                    <span className='group4-span7'>Benefits</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='group4-div2'>
                        <div className='group4-div3'>
                            <div className='group4-div3'>
                                <span className='group4-span3'>Lorem Ipsum</span>
                                <span className='group4-span4'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</span>
                                <div className='group4-div4'>
                                    <span className='group4-span5'>$79.99</span>
                                    <button className='group4-button1'>
                                        <span className='group4-span6'>ENROLL NOW</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ width: "90%", marginTop: "20px" }}>
                                <div style={{ display: "flex", marginBottom: "15px" }}>
                                    <span className='group4-span7'>Benefits</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='group4-div2'>
                        <div className='group4-div3'>
                            <div className='group4-div3'>
                                <span className='group4-span3'>Lorem Ipsum</span>
                                <span className='group4-span4'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</span>
                                <div className='group4-div4'>
                                    <span className='group4-span5'>$79.99</span>
                                    <button className='group4-button1'>
                                        <span className='group4-span6'>ENROLL NOW</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ width: "90%", marginTop: "20px" }}>
                                <div style={{ display: "flex", marginBottom: "15px" }}>
                                    <span className='group4-span7'>Benefits</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='group4-div2'>
                        <div className='group4-div3'>
                            <div className='group4-div3'>
                                <span className='group4-span3'>Lorem Ipsum</span>
                                <span className='group4-span4'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</span>
                                <div className='group4-div4'>
                                    <span className='group4-span5'>$79.99</span>
                                    <button className='group4-button1'>
                                        <span className='group4-span6'>ENROLL NOW</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ width: "90%", marginTop: "20px" }}>
                                <div style={{ display: "flex", marginBottom: "15px" }}>
                                    <span className='group4-span7'>Benefits</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                                <div className='group4-div5'>
                                    <img className='.group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                                    <span className='.group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='flexDiv' style={{ width: "100%", height: "900px" }} >
            <div className='flexDiv' style={{ width: "80%", background: "#ffffff" }}>
                <div style={{ width: "50%", paddingLeft: "20%" }}>
                    <span className='group5-span1'>license up, excel in real estate</span>
                    <div style={{ width: "100%" }}>
                        <span className='group5-span2' style={{ color: "#181C1D" }}>Four Reasons You<br/>Should </span>
                        <span className='group5-span2' style={{ color: "#D3D04F" }}>Get Your Real<br/>Estate License</span><br/>
                        <span className='group5-span3'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</span>
                    </div>
                    <div className='group5-div1'>
                        <div className='group5-div2'>
                            <img className='group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                            <span className='group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                        </div>
                        <div className='group5-div2'>
                            <img className='group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                            <span className='group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                        </div>
                        <div className='group5-div2'>
                            <img className='group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                            <span className='group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                        </div>
                        <div className='group5-div2'>
                            <img className='group4-image1' src={PricingCheck} />&nbsp;&nbsp;&nbsp;
                            <span className='group4-span8'>Lorem Ipsum Dolor Sit Amet</span>
                        </div>
                    </div>
                    <button  className='get-started'>
                        <span >GET STARTED</span>
                    </button>
                </div>
                <div className='group5-div3'>
                    <div style={{ width: "100%" }}>
                        <img style={{ width: "904px", marginLeft: "auto"}} src={PricingMan} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Pricing
