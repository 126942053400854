import './css/WhyUs.css';
import WhyusImage1 from '../../assets/whyUs1.png'
import WhyusCheck from '../../assets/check.svg'
import Phone from '../../assets/ic_baseline-phone.png'
import Envelope from '../../assets/fa6-solid_envelope.svg'
import Map from '../../assets/bxs_map.png'

const WhyUs = () => {
    return <div>
        <div className='w-group1-div1'>
            <span>Why Us</span>
        </div>

        <div className='flexDiv' style={{ width: "100%", height: "900px" }}>
            <div className='flexDiv flexRow' style={{ width: "80%" }}>
                <div style={{width: "50%"}}>
                    <img style={{ width: "704px", height: "715px", marginLeft: "auto" }} src={WhyusImage1} alt=''/>
                </div>
                <div className='w-group2-div1'>
                    <span className='w-group2-span1' >new york</span>
                    <span className='w-group2-span2'>Lorem Ipsum Dolor Sit Amet Sien Denmy</span>
                </div>
            </div>
        </div>

        <div className='w-group3-div1'>
            <div className='flexDiv flexColumn' style={{ width: "80%", height: "100%" }}>
                <span className='w-group3-span1'>My real school</span>
                <span className='w-group3-span2'>Why Choose Us</span>
                <div style={{ width: "50%", paddingBottom: "20px" }}>
                    <span className='w-group3-span3'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc sed blandit libero volutpat sed cras. Nulla pharetra diam sit amet nisl suscipit. Sit amet cursus sit amet dictum. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. Nunc non blandit massa enim nec. Pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem. Sit amet massa vitae tortor.
                    </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className='w-group3-div3'>
                        <img className='w-group3-img' src={WhyusCheck} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group3-span4'>Lorem Ipsum Dolor Sit Amet</span>
                    </div>
                    <div className='w-group3-div4'>
                        <img className='w-group3-img' src={WhyusCheck} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group3-span4'>Lorem Ipsum Dolor Sit Amet</span>
                    </div>
                    <div className='w-group3-div4'>
                        <img className='w-group3-img' src={WhyusCheck} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group3-span4'>Lorem Ipsum Dolor Sit Amet</span>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className='w-group3-div3'>
                        <img className='w-group3-img' src={WhyusCheck} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group3-span4'>Lorem Ipsum Dolor Sit Amet</span>
                    </div>
                    <div className='w-group3-div4'>
                        <img className='w-group3-img' src={WhyusCheck} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group3-span4'>Lorem Ipsum Dolor Sit Amet</span>
                    </div>
                    <div className='w-group3-div4'>
                        <img className='w-group3-img' src={WhyusCheck} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group3-span4'>Lorem Ipsum Dolor Sit Amet</span>
                    </div>
                </div>
            </div>
        </div>

        <div className='flexDiv flexRow' style={{ width: "100%", height: "530px", paddingTop: "50px" }}>
            <div className='flexDiv flexRow' style={{ width: "50%", marginBottom: "auto" }}>
                <div className='w-group4-div111'>
                    <span className='w-group4-span1' style={{ color: "#181C1D" }}>Get in Touch With </span>
                    <span className='w-group4-span1' style={{ color: "#D3D04F" }}>Our Professionals</span>
                    <span className='w-group4-span2'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</span>
                    <div className='w-group4-div222'>
                        <img className='w-group4-img' src={Phone} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group4-span3'>+123 4567 879</span>
                    </div>
                    <div className='w-group4-div222'>
                        <img className='w-group4-img' src={Envelope} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group4-span3'>myrealschool@info.com</span>
                    </div>
                    <div className='w-group4-div222'>
                        <img className='w-group4-img' src={Map} alt='' />&nbsp;&nbsp;&nbsp;
                        <span className='w-group4-span3'>Lorem ipsum dolor sit amet, codac</span>
                    </div>

                </div>
            </div>
            <div style={{ width: "50%", marginLeft: "30px"}}>
                <form className="items-center justify-between mt-4" action="" method="POST">
                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <input type="text" placeholder="Name" className='input1' />
                        <input type="email" placeholder="Email" className='input1' />
                        <input type="tel" placeholder="Phone" className='input1' />
                        <textarea rows={4} cols={50} className='input1' style={{ maskBorderOutset: "none" }} placeholder="Message"></textarea>
                            <button className='w-group4-button1'>SEND MESSAGE</button>
                    </div>
                </form>
            </div>
        </div>


    </div>
}
  export default WhyUs
  