import "./css/Faqs.css";
import ArrowUp from '../../assets/arrow-up.png'


const FAQs = () => {
  const hideThis = (id: String) => {
    var text = document.getElementById(`hide${id}`);
    var image = document.getElementById(`image${id}`);
    var title = document.getElementById(`title${id}`);
    console.log(id)
    if (text?.hasAttribute('hidden')) {
      text?.removeAttribute('hidden')
      image?.setAttribute('class', 'f-group3-img1-active')
      title?.setAttribute('class', 'f-group3-div-title-active')
    } else {
      text?.setAttribute('hidden', '')
      image?.setAttribute('class', 'f-group3-img1')
      title?.setAttribute('class', 'f-group3-div-title')
    }
  }

  return (
    <div style={{ marginBottom: "100px" }}>
      <div className="f-group1-div1">
        <span>Get Answers</span>
      </div>

      <div className="f-group1-div2">
        <span style={{ color: "#181C1D" }}>Frequently Asked </span><br />
        <span style={{ color: "#D3D04F" }}>Questions</span>
      </div>

      <div className="f-group3-div1">
        <div className="f-group3-div2">
          <div className="f-group3-div3">
            <div id="title1" className="f-group3-div-title">
              <p>Lorem ipsum dolor sit amet consectur?</p>
            </div>
            <button id="1" className="f-group3-button1" onClick={() => { hideThis("1") }}><img id="image1" className="f-group3-img1" src={ArrowUp} alt="" /></button>
          </div>
          <div id="hide1" hidden style={{ marginBottom: "30px" }}>
            <span className="f-group3-span1" style={{ color: "#535C5E" }} >Per sed, mattis. Integer viverra euismod maecenas incidunt, phasellus consequatur aliquam nihil temporibus in assumenda? Aute praesentium fugiat. Perspiciatis, ultrices deserunt convallis, eius at non.</span>
          </div>
        </div>
      </div>

      <div className="f-group3-div1">
        <div className="f-group3-div2">
          <div className="f-group3-div3">
            <div id="title2" className="f-group3-div-title">
              <p>Lorem ipsum dolor sit amet consectur?</p>
            </div>
            <button id="2" className="f-group3-button1" onClick={() => { hideThis("2") }}><img id="image2" className="f-group3-img1" src={ArrowUp} alt="" /></button>
          </div>
          <div id="hide2" hidden style={{ marginBottom: "30px" }}>
            <span className="f-group3-span1" style={{ color: "#535C5E" }} >Per sed, mattis. Integer viverra euismod maecenas incidunt, phasellus consequatur aliquam nihil temporibus in assumenda? Aute praesentium fugiat. Perspiciatis, ultrices deserunt convallis, eius at non.</span>
          </div>
        </div>
      </div>

      <div className="f-group3-div1">
        <div className="f-group3-div2">
          <div className="f-group3-div3">
            <div id="title3" className="f-group3-div-title">
              <p>Lorem ipsum dolor sit amet consectur?</p>
            </div>
            <button id="3" className="f-group3-button1" onClick={() => { hideThis("3") }}><img id="image3" className="f-group3-img1" src={ArrowUp} alt="" /></button>
          </div>
          <div id="hide3" hidden style={{ marginBottom: "30px" }}>
            <span className="f-group3-span1" style={{ color: "#535C5E" }} >Per sed, mattis. Integer viverra euismod maecenas incidunt, phasellus consequatur aliquam nihil temporibus in assumenda? Aute praesentium fugiat. Perspiciatis, ultrices deserunt convallis, eius at non.</span>
          </div>
        </div>
      </div>

      <div className="f-group3-div1">
        <div className="f-group3-div2">
          <div className="f-group3-div3">
            <div id="title4" className="f-group3-div-title">
              <p>Lorem ipsum dolor sit amet consectur?</p>
            </div>
            <button id="4" className="f-group3-button1" onClick={() => { hideThis("4") }}><img id="image4" className="f-group3-img1" src={ArrowUp} alt="" /></button>
          </div>
          <div id="hide4" hidden style={{ marginBottom: "30px" }}>
            <span className="f-group3-span1" style={{ color: "#535C5E" }} >Per sed, mattis. Integer viverra euismod maecenas incidunt, phasellus consequatur aliquam nihil temporibus in assumenda? Aute praesentium fugiat. Perspiciatis, ultrices deserunt convallis, eius at non.</span>
          </div>
        </div>
      </div>

      <div className="f-group3-div1">
        <div className="f-group3-div2">
          <div className="f-group3-div3">
            <div id="title5" className="f-group3-div-title">
              <p>Lorem ipsum dolor sit amet consectur?</p>
            </div>
            <button id="5" className="f-group3-button1" onClick={() => { hideThis("5") }}><img id="image5" className="f-group3-img1" src={ArrowUp} alt="" /></button>
          </div>
          <div id="hide5" hidden style={{ marginBottom: "30px" }}>
            <span className="f-group3-span1" style={{ color: "#535C5E" }} >Per sed, mattis. Integer viverra euismod maecenas incidunt, phasellus consequatur aliquam nihil temporibus in assumenda? Aute praesentium fugiat. Perspiciatis, ultrices deserunt convallis, eius at non.</span>
          </div>
        </div>
      </div>

    </div>
  );
};
export default FAQs;
