import "./css/SignUp.css";

const SignUp = () => {
    return (
        <div className="s-main-div">
            <div className="s-second-div">
                <div className="s-title-div">
                    <span style={{ color: "black" }}>Create My</span>
                    <span style={{ color: "#17594A" }}>Real</span>
                    <span style={{ color: "#8EAC50" }}>School</span>
                </div>
                <div className="s-title-div">
                    <span style={{ color: "black" }}>account</span>
                </div>
                <p className="s-sub-title">Lorem ipsum dolor sit amet, consetetur sadipscing</p>
                <div className="s-form-div" style={{ width: "75%" }}>
                    <form>
                        <div className="s-input-div">
                            <input type="text" className="s-input" style={{ marginRight: "15px" }} id="firestName" placeholder="First Name" autoFocus required />
                            <input type="text" className="s-input" style={{ marginLeft: "15px" }} id="lastName" placeholder="Last Name" required />
                        </div>
                        <div className="s-input-div">
                            <select className="s-input" style={{ marginRight: "15px" }} required>
                                <option selected hidden>Choose State</option>
                                <option value="NY">NY</option>
                                <option value="California">California</option>
                                <option value="Canada">Canada</option>
                            </select>
                            <input type="number" className="s-input" style={{ marginLeft: "15px" }} id="phone" placeholder="Phone" required />
                        </div>
                        <div className="s-input-div">
                            <input type="email" className="s-input" id="email" placeholder="Email" required />
                        </div>
                        <div className="s-input-div">
                            <input type="password" className="s-input" style={{ marginRight: "15px" }} id="password" placeholder="Password" required />
                            <input type="password" className="s-input" style={{ marginLeft: "15px" }} id="confirmPassword" placeholder="Confirm Password" required />
                        </div>
                        <div className="s-mainCheckTerm-div">
                            <div className="s-checkAcceptTerm-div">
                                <input type="checkbox" className="form-check-input" id="rememberMe" /> &nbsp;&nbsp;
                                <div>
                                    <span className="s-accept-span" style={{ color: "#959595"  }}>Accept </span>
                                    <a href="#" className="s-term-span" style={{textDecoration: "none"}}>Terms of Use</a>
                                    <span className="s-accept-span" style={{ color: "#959595"  }}> and </span>
                                    <a href="#" className="s-term-span" style={{textDecoration: "none"}}>Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                        <div className="s-button-div">
                            <button type="submit" className="s-submit-button">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="l-outer-div">
                <span className="l-outer-div-span1">Already have an account? </span>
                <a href="/login"><span className="l-outer-div-span2">Sign In</span></a>
            </div>
        </div>

    );
}
export default SignUp
