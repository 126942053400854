import "./css/Home.css";
import MDITwitterImg from "./home-static/img/mdi-twitter.svg";
import RIInstagramImg from "./home-static/img/ri-instagram-fill.svg";
import RIFacebookImg from "./home-static/img/ri-facebook-fill.svg";
import OnlineCourseImg from "./home-static/img/online-courses.png";
import TestImg from "./home-static/img/test.svg";
import VideoCoursesImg from "./home-static/img/video-courses.svg";
import UploadImg from "./home-static/img/upload.svg";
import Group124Img from "./home-static/img/group-124.png";
import Group48Img from "./home-static/img/group-48.svg";
import Group16Img from "./home-static/img/group-16.svg";
import Group148Img from "./home-static/img/group-148.svg";
import Group149Img from "./home-static/img/group-149.svg";
import MaskGroupImg from "./home-static/img/mask-group.png";
import CheckImg from "./home-static/img/group-54-1-4.svg";
import Rectangle225Img from "./home-static/img/rectangle-225.png";
import ArrowCurveUpLeftImg from "./home-static/img/3-3.png";
import Rectangle226Img from "./home-static/img/rectangle-226.png";
import ArrowCurveDownRightImg from "./home-static/img/3-4.png";
import Group63Img from "./home-static/img/group-163.png";
import Vector1Img from "./home-static/img/vector-1.svg";
import Path31Img from "./home-static/img/path-31.svg";
import Group124 from "./home-static/img/group-124.png";
import Group125 from "./home-static/img/group-125.svg";
import Group126 from "./home-static/img/group-126.svg";
import Group127 from "./home-static/img/group-127.svg";
import ArrowCurveMidLeftImg from "./home-static/img/3-2.png";
import Group531 from "./home-static/img/group-53-1.png";
import Group541 from "./home-static/img/group-54-1.svg";
import ArrowUp from "./home-static/img/iconamoon-arrow-up-2.svg";
import ArrowDown from "./home-static/img/iconamoon-arrow-up-2-1.svg";
import Ellipse8Img from "./home-static/img/ellipse-8.png";
import Ellipse81Img from "./home-static/img/ellipse-8-1.png";
import Group121Img from "./home-static/img/group-121.svg";
import Group171Img from "./home-static/img/group-171.svg";
import Group172Img from "./home-static/img/group-172.png";
import MaskGroup2Img from "./home-static/img/mask-group-2.png";

const Home = () => {
  return (
    <div className="homepage">
      <div className="div">
        <div className="overlap">
          <img className="img" alt="Mdi twitter" src={MDITwitterImg} />
          <img
            className="img"
            alt="Ri instagram fill"
            src={RIInstagramImg}
          />
          <img
            className="img"
            alt="Ri facebook fill"
            src={RIFacebookImg}
          />
          <div className="rectangle" />
          <div className="group">
            <div className="overlap-group">
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <p className="text-wrapper">
                    Complete the online MyRealSchool course at your own pace.
                  </p>
                  <img
                    className="online-courses"
                    alt="Online courses"
                    src={OnlineCourseImg}
                  />
                  <div className="text-wrapper-2">Online Course</div>
                </div>
              </div>
              <div className="overlap-wrapper">
                <div className="overlap-2">
                  <p className="p">
                    Take the state exam using our study guides to help you
                    prepare.
                  </p>
                  <img className="img-2" alt="Test" src={TestImg} />
                  <div className="text-wrapper-3">State Exam</div>
                </div>
              </div>
              <div className="div-wrapper">
                <div className="overlap-3">
                  <p className="p">
                    Find a sponsoring broker to help you learn the ropes.
                  </p>
                  <img
                    className="img-2"
                    alt="Video courses"
                    src={VideoCoursesImg}
                  />
                  <div className="text-wrapper-4">Broker</div>
                </div>
              </div>
              <div className="group-2">
                <div className="overlap-4">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <div className="text-wrapper-5">04</div>
                    </div>
                  </div>
                  <p className="text-wrapper-6">
                    Start your new career with a commission-based income with no
                    limits
                  </p>
                  <img className="img-2" alt="Upload" src={UploadImg} />
                  <div className="text-wrapper-7">Apply License</div>
                </div>
              </div>
            </div>
          </div>
          <p className="get-your-real-estate">
            <span className="span">
              Get Your Real Estate
              <br />
              License In{" "}
            </span>
            <span className="text-wrapper-8">4 Simple Steps:</span>
          </p>
          <img className="group-4" alt="Group" src={Group124Img} />
          <img className="group-5" alt="Group" src={Group48Img} />
          <img className="group-6" alt="Group" src={Group16Img} />
          <div className="how-it-works">HOW IT WORKS</div>
          <div className="text-wrapper-9">03</div>
          <div className="text-wrapper-10">01</div>
          <div className="text-wrapper-11">02</div>
          <img
            className="mask-group"
            alt="Mask group"
            src={MaskGroupImg}
          />
          <div className="rectangle-2" />
          <img className="group-7" alt="Group" src={Group148Img} />
          <img className="group-8" alt="Group" src={Group149Img} />
          <div className="group-9">
            <div className="group-10">
              <p className="limitless-learning">
                <span className="text-wrapper-12">Limitless </span>
                <span className="text-wrapper-8">
                  Learning,
                  <br />
                </span>
                <span className="text-wrapper-12">Limitless </span>
                <span className="text-wrapper-8">Possibilities!</span>
              </p>
              <div className="get-YOUR-LICENSE-NOW">GET YOUR LICENSE NOW</div>
              <p className="text-wrapper-13">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores
              </p>
              <div className="group-11">
                <p className="text-wrapper-14">Lorem ipsum dolor sit amet</p>
                <img
                  className="group-12"
                  alt="Group"
                  src={CheckImg}
                />
              </div>
              <div className="group-13">
                <p className="text-wrapper-14">Lorem ipsum dolor sit amet</p>
                <img
                  className="group-12"
                  alt="Group"
                  src={CheckImg}
                />
              </div>
              <div className="group-14">
                <p className="text-wrapper-14">Lorem ipsum dolor sit amet</p>
                <img
                  className="group-12"
                  alt="Group"
                  src={CheckImg}
                />
              </div>
              <div className="frame">
                <div className="text-wrapper-15">GET STARTED</div>
              </div>
            </div>
            <div className="overlap-5">
              <img
                className="rectangle-3"
                alt="Rectangle"
                src={Rectangle225Img}
              />
              <div className="overlap-6">
                <div className="overlap-7">
                  <img className="element" alt="Element" src={ArrowCurveUpLeftImg} />
                  <img
                    className="rectangle-4"
                    alt="Rectangle"
                    src={Rectangle226Img}
                  />
                </div>
                <img className="element-2" alt="Element" src={ArrowCurveDownRightImg} />
              </div>
            </div>
          </div>
          <img className="group-15" alt="Group" src={Group63Img} />
        </div>
        <div className="overlap-9">
          <div className="group-17">
            <div className="overlap-10">
              <div className="text-wrapper-19">Choose your state</div>
              <img className="vector" alt="Vector" src={Vector1Img} />
            </div>
            <p className="enroll-today-for">
              <span className="span">
                Enroll Today <br />
                for
              </span>
              <span className="text-wrapper-20">&nbsp;</span>
              <span className="text-wrapper-8">Success!</span>
            </p>
            <p className="text-wrapper-21">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            </p>
            <p className="text-wrapper-22">LAUNCH YOUR REAL ESTATE JOURNEY</p>
            <p className="text-wrapper-23">Choose your state to get started:</p>
            <div className="frame-3">
              <div className="text-wrapper-24">GET STARTED</div>
            </div>
            <img className="path" alt="Path" src={Path31Img} />
          </div>
          <img className="group-18" alt="Group" src={Group124} />
          <img className="group-19" alt="Group" src={Group125} />
          <img className="group-20" alt="Group" src={Group126} />
          <img className="group-21" alt="Group" src={Group127} />
          <img className="element-3" alt="Element" src={ArrowCurveMidLeftImg} />
        </div>
        <img className="group-22" alt="Group" src={Group531} />
        <div className="group-23">
          <p className="unlock-success-with">
            <span className="span">
              Unlock Success With
              <br />
              My
            </span>
            <span className="text-wrapper-25">Real</span>
            <span className="text-wrapper-26">School</span>
            <span className="span">: </span>
            <span className="text-wrapper-8">
              Your
              <br />
              Path to Excellence
            </span>
          </p>
          <div className="why-choose-us">WHY CHOOSE US</div>
          <p className="text-wrapper-27">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
          </p>
          <div className="group-24">
            <div className="text-wrapper-28">Expert Trainers</div>
            <img className="group-12" alt="Group" src={Group541} />
          </div>
          <div className="group-25">
            <div className="text-wrapper-28">Online Learning</div>
            <img className="group-12" alt="Group" src={Group541} />
          </div>
          <div className="group-26">
            <div className="text-wrapper-28">Fully Approved By State</div>
            <img className="group-12" alt="Group" src={Group541} />
          </div>
          <div className="group-27">
            <div className="text-wrapper-28">Lifetime Access</div>
            <img className="group-12" alt="Group" src={Group541} />
          </div>
          <div className="frame-4">
            <div className="text-wrapper-24">GET STARTED</div>
          </div>
        </div>
        <div className="group-28">
          <div className="group-29">
            <div className="group-33">
              <p className="frequently-asked">
                <span className="span">Frequently Asked </span>
                <span className="text-wrapper-8">Questions</span>
              </p>
              <p className="text-wrapper-40">
                Per sed, mattis. Integer viverra euismod maecenas incidunt,
                phasellus consequatur aliquam nihil temporibus in assumenda?
                Aute praesentium fugiat. Perspiciatis, ultrices deserunt
                convallis, eius at non.
              </p>
              <div className="rectangle-6" />
              <div className="group-34">
                <p className="text-wrapper-41">
                  Lorem ipsum dolor sit amet consectur?
                </p>
                <img
                  className="iconamoon-arrow-up"
                  alt="Iconamoon arrow up"
                  src={ArrowUp}
                />
              </div>
              <div className="group-35">
                <div className="rectangle-7" />
                <div className="group-36">
                  <p className="text-wrapper-42">
                    Lorem ipsum dolor sit amet consectur?
                  </p>
                  <img
                    className="iconamoon-arrow-up"
                    alt="Iconamoon arrow up"
                    src={ArrowDown}
                  />
                </div>
              </div>
              <div className="group-37">
                <div className="rectangle-7" />
                <div className="group-36">
                  <p className="text-wrapper-42">
                    Lorem ipsum dolor sit amet consectur?
                  </p>
                  <img
                    className="iconamoon-arrow-up"
                    alt="Iconamoon arrow up"
                    src={ArrowDown}
                  />
                </div>
              </div>
              <div className="group-38">
                <div className="rectangle-7" />
                <div className="group-36">
                  <p className="text-wrapper-42">
                    Lorem ipsum dolor sit amet consectur?
                  </p>
                  <img
                    className="iconamoon-arrow-up"
                    alt="Iconamoon arrow up"
                    src={ArrowDown}
                  />
                </div>
              </div>
              <div className="group-39">
                <div className="rectangle-7" />
                <div className="group-36">
                  <p className="text-wrapper-42">
                    Lorem ipsum dolor sit amet consectur?
                  </p>
                  <img
                    className="iconamoon-arrow-up"
                    alt="Iconamoon arrow up"
                    src={ArrowDown}
                  />
                </div>
              </div>
              <div className="group-40">
                <div className="rectangle-7" />
                <div className="group-36">
                  <p className="text-wrapper-42">
                    Lorem ipsum dolor sit amet consectur?
                  </p>
                  <img
                    className="iconamoon-arrow-up"
                    alt="Iconamoon arrow up"
                    src={ArrowDown}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="faq-s">FAQS</div>
        </div>
        <div className="group-41">
          <div className="group-42">
            <div className="group-43">
              <p className="review-s-from">
                <span className="span">Review&#39;s From </span>
                <span className="text-wrapper-8">Students</span>
              </p>
              <p className="text-wrapper-43">
                Saepe quo labore aenean dictumst expedita commodi auctor, nisl,
                lorem iusto feugiat nemo reiciendis laboris.
              </p>
              <div className="group-wrapper">
                <div className="group-44">
                  <div className="overlap-group-5">
                    <div className="rectangle-8" />
                    <div className="rectangle-9" />
                    <img
                      className="mask-group-2"
                      alt="Mask group"
                      src={MaskGroup2Img}
                    />
                    <div className="group-45">
                      <p className="text-wrapper-44">
                        Per sed, mattis. Integer viverra euismod maecenas
                        incidunt, phasellus consequatur aliquam nihil temporibus
                        in assumenda? Aute praesentium fugiat. Perspiciatis,
                        ultrices deserunt convallis, eius at non.
                      </p>
                      <img
                        className="ellipse"
                        alt="Ellipse"
                        src={Ellipse8Img}
                      />
                      <div className="text-wrapper-45">Joan Smith</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-46">
                <div className="group-44">
                  <div className="overlap-group-5">
                    <div className="rectangle-8" />
                    <div className="rectangle-9" />
                    <img
                      className="mask-group-2"
                      alt="Mask group"
                      src={MaskGroup2Img}
                    />
                    <div className="group-45">
                      <p className="text-wrapper-44">
                        Per sed, mattis. Integer viverra euismod maecenas
                        incidunt, phasellus consequatur aliquam nihil temporibus
                        in assumenda? Aute praesentium fugiat. Perspiciatis,
                        ultrices deserunt convallis, eius at non.
                      </p>
                      <img
                        className="ellipse"
                        alt="Ellipse"
                        src={Ellipse81Img}
                      />
                      <div className="text-wrapper-45">John Doe</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="group-47" alt="Group" src={Group121Img} />
            <img className="group-48" alt="Group" src={Group171Img} />
            <img className="group-49" alt="Group" src={Group172Img} />
            <div className="group-50">
              <div className="ellipse-2" />
              <div className="ellipse-3" />
              <div className="ellipse-4" />
            </div>
          </div>
          <div className="testimonial">TESTIMONIAL</div>
        </div>
      </div>
    </div>
  );
};
export default Home;
