import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import DefaultPublicLayout from './layouts/DefaultPublicLayout'

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <DefaultPublicLayout />
      </BrowserRouter>
    </React.StrictMode>
  )
}

export default App
