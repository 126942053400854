import "./css/ContactUs.css";
import Group123Img from "./contactus-static/img/group-123.png";
import EnvelopeImg from "./contactus-static/img/fa6-solid-envelope.svg";
import BxsMapImg from "./contactus-static/img/bxs-map.svg";
import BaselinePhoneImg from "./contactus-static/img/ic-baseline-phone.svg";
import Group120Img from "./contactus-static/img/group-120.svg";
import Group35Img from "./contactus-static/img/group-35.png";
import Group127Img from "./contactus-static/img/group-127.png";
import Group125Img from "./contactus-static/img/group-125.svg";
import Group126Img from "./contactus-static/img/group-126.svg";

const ContactUS = () => {
  return (
    <div className="contact">
      <div className="div">
        <div className="overlap-group">
          <img className="img" alt="Group" src={Group123Img} />
          <div className="group-2">
            <div className="group-3">
              <p className="p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt.
              </p>
              <div className="group-4">
                <div className="group-5">
                  <div className="text-wrapper-4">myrealschool@info.com</div>
                  <img
                    className="solid-envelope"
                    alt="Solid envelope"
                    src={EnvelopeImg}
                  />
                </div>
                <div className="group-6">
                  <p className="text-wrapper-4">
                    Lorem ipsum dolor sit amet, codac
                  </p>
                  <img className="img-2" alt="Bxs map" src={BxsMapImg} />
                </div>
                <div className="group-7">
                  <img
                    className="img-2"
                    alt="Ic baseline phone"
                    src={BaselinePhoneImg}
                  />
                  <div className="text-wrapper-5">+123 4567 879</div>
                </div>
              </div>
              <p className="get-in-touch-with">
                <span className="span">Get in Touch With </span>
                <span className="text-wrapper-6">Our Professionals</span>
              </p>
            </div>
            <div className="group-8">
              <div className="rectangle-2" />
              <div className="text-wrapper-7">Name</div>
            </div>
            <div className="group-9">
              <div className="rectangle-2" />
              <div className="text-wrapper-7">Email</div>
            </div>
            <div className="group-10">
              <div className="rectangle-2" />
              <div className="text-wrapper-7">Phone</div>
            </div>
            <div className="group-11">
              <div className="rectangle-3" />
              <div className="text-wrapper-7">Message...</div>
            </div>
            <div className="div-wrapper">
              <div className="text-wrapper-8">SEND MESSAGE</div>
            </div>
          </div>
          <img className="group-14" alt="Group" src={Group120Img} />
          <img className="group-15" alt="Group" src={Group35Img} />
          <div className="ellipse" />
        </div>
        <div className="overlap-3">
          <div className="text-wrapper-20">Contact</div>
          <img className="group-16" alt="Group" src={Group127Img} />
          <img className="group-17" alt="Group" src={Group125Img} />
          <img className="group-18" alt="Group" src={Group126Img} />
        </div>
      </div>
    </div>
  );
};
export default ContactUS;
