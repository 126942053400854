import './footer.css';
import WhiteLogo from '../assets/whiteLogo.svg'
import FooterSocial from '../assets/footer1.svg'

const PublicFooter = () => {
  return (
    <>
      <div className='main-div'>
        <div className='sub-div'>
            <div style={{ width: "30%", display: "flex",  flexDirection: "column" }}>
                <img style={{ width: "347.4px", height: "69.61px", margin: "10px 0" }} src={WhiteLogo} alt='...'/>
                <span className='f-group1-span1'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span>
                <div style={{display: "flex", flexDirection: "row", margin: "10px 0"}}>
                    <img src={FooterSocial} alt=''/>
                </div>
            </div>

            <div style={{ width: "10%" }}></div>
            <div style={{ width: "10%", display: "flex", flexDirection: "column" }}>
                <h1 className='f-group2-span1'>Quick Links</h1>
                <a href='/pricing' className='f-group2-a'>Pricing</a>
                <a href='/why-us' className='f-group2-a'>Why Us</a>
                <a href='/#' className='f-group2-a'>Career Hub</a>
                <a href='/faqs' className='f-group2-a'>FAQs</a>
                <a href='contact-us' className='f-group2-a'>Contact Us</a>
            </div>

            <div style={{ width: "10%" }}></div>
            <div style={{ width: "30%", display: "flex",  flexDirection: "column" }}>
                <h1 className='f-group2-span1'>Quick Links</h1>
                <div className='f-group3-div1'>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                </div>
                <div className='f-group3-div1'>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                </div>
                <div className='f-group3-div1'>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                </div>
                <div className='f-group3-div1'>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                </div>
                <div className='f-group3-div1'>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                    <span className='f-group3-span1'>Lorem Ipsum</span>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}
export default PublicFooter
