import "./css/Login.css";
import EmailImage from '../../assets/ei_envelope.svg'
import PasswordImage from '../../assets/tabler_lock.svg'

const Login = () => {
    return (
        <div className="l-main-div">
            <div className="l-second-div">
                <div className="l-title-div">
                    <span style={{ color: "black" }}>Sign in to My</span>
                    <span style={{ color: "#17594A" }}>Real</span>
                    <span style={{ color: "#8EAC50" }}>School</span>
                </div>
                <p className="l-sub-title">Lorem ipsum dolor sit amet, consetetur sadipscing</p>
                <div className="l-form-div">
                    <form>
                        <div className="l-input-div">
                            <img src={EmailImage} alt="" />
                            <input type="email" className="l-input" id="email" aria-describedby="emailHelp" placeholder="Email" autoFocus required />
                        </div>
                        <div className="l-input-div">
                            <img src={PasswordImage} alt="" />
                            <input type="password" className="l-input" id="password" placeholder="Password" />
                        </div>
                        <div className="l-checkForgot-div">
                            <div className="l-rememberMe-div">
                                <input type="checkbox" className="form-check-input" id="rememberMe" /> &nbsp;&nbsp;
                                <span >Remember me</span>
                            </div>
                            <div className="l-forgot-div">
                                <a href="#" style={{ marginLeft: "auto" }}>Forgot Password?</a>
                            </div>
                        </div>
                        <div className="l-button-div">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="l-outer-div">
                <span className="l-outer-div-span1">Don’t have an account? </span>
                <a href="/sign-up"><span className="l-outer-div-span2">Sign Up</span></a>
            </div>
        </div>

    );
}
export default Login
