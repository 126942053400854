import { useLocation } from "react-router-dom"
import HeaderLogo from '../assets/header_logo.png'
import './Public.css';
const PublicHeader = () => {
  const location = useLocation();
  const isActive = (path: string) => {
    if (location.pathname === path) {
      return "active-link";
    }
    return "";
  }
  return (
    <div className='col' style={{ backgroundColor: 'rgb(255,255,255, .75)', height: '100px', background: "#F9FDFD" }}>
      <nav
        className='navbar navbar-expand-lg justify-content-start align-items-center'
        style={{ height: '100%', padding: 0, margin: '0 10%', position: "relative" }}
      >
        <a className='navbar-brand' href='#'>
          <img src={HeaderLogo} height={"69.09px"} width={"347.39px"} alt='Header Logo' />
        </a>
        <button
          className='navbar-toggler justify-content-end'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
          style={{ position: "absolute", right: "0" }}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className='collapse navbar-collapse justify-content-end align-items-center'
          id='navbarNavAltMarkup'
        >
          <div className='navbar-nav'>

            <a className={`nav-item nav-link test px-3 ${isActive("/")}`} href='/'>
              Home
            </a>
            <a className={`nav-item nav-link test px-3 ${isActive("/pricing")}`} href='/pricing'>
              Pricing
            </a>
            <a className={`nav-item nav-link test px-3 ${isActive("/why-us")}`} href='/why-us'>
              Why Us
            </a>
            <a className={`nav-item nav-link test px-3 ${isActive("#")}`} href='#'>
              Carrer Hub
            </a>
            <a className={`nav-item nav-link test px-3 ${isActive("/faqs")}`} href='/faqs'>
              FAQs
            </a>
            <a className={`nav-item nav-link test px-3 ${isActive("/contact-us")}`} href='/contact-us'>
              Contact Us
            </a>
            <a href='/login'>
              <button className="navbar-nav-button">
                <span className="navbar-nav-span" >LOGIN</span>
              </button>
            </a>
          </div>
        </div>
      </nav>
    </div>
  )
}
export default PublicHeader
