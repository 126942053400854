import { Routes, Route } from "react-router-dom"
import Home from '../pages/public/Home'
import Pricing from '../pages/public/Pricing'
import WhyUs from '../pages/public/WhyUs'
import ContactUS from "../pages/public/ContactUs"
import FAQs from "../pages/public/Faqs"
import Courses from "../pages/public/Courses"
import Login from '../pages/public/Login'
import SignUp from '../pages/public/SignUp'

const PublicContent = () => {
  return (
    <div className='col'>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/pricing" element={ <Pricing/> } />
        <Route path="/why-us" element={ <WhyUs/> } />
        <Route path="/contact-us" element={ <ContactUS/> } />
        <Route path="/faqs" element={ <FAQs/> } />
        <Route path="/courses" element={ <Courses/> } />

        <Route path="/login" element={ <Login/> } />
        <Route path="/sign-up" element={ <SignUp/> } />
      </Routes>
    </div>
  )
}
export default PublicContent
