import PublicContent from "../components/PublicContent";
import PublicFooter from "../components/PublicFooter";
import PublicHeader from "../components/PublicHeader";

const DefaultPublicLayout = () => {
  return (
    <div className='container-fluid' style={{ padding: 0 }}>
      <div className='column'>
        <PublicHeader />
        <PublicContent />
        <PublicFooter />
      </div>
    </div>
  )
}
export default DefaultPublicLayout
