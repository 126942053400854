import "./css/Courses.css";
import SampleVideo from '../../assets/video/sampleVideo.mp4'
import NextImage from '../../assets/next.svg'
import PrevImage from '../../assets/previous.svg'
import SampleProfile from '../../assets/sampleProfile.jpg'
import MenuImage from '../../assets/menu.svg'


const Courses = () => {
    var bar = 'bar1';
    const activeBar = (id: string) => {
        var activeB = document.getElementById(id);
        var lastActiveB = document.getElementById(bar);

        var activeDivBar = document.getElementById(`div${id}`);
        var lastActiveBDivBar = document.getElementById(`div${bar}`);

        if(id !== bar){
            activeB?.setAttribute('class', 'c-group2-button1-active');
            lastActiveB?.setAttribute('class', 'c-group2-button1');

            activeDivBar?.removeAttribute('hidden')
            lastActiveBDivBar?.setAttribute('hidden', '')
            bar = id;
        }
        console.log('active', bar);
    };

    const previousBar = () => {
        var curr = bar.charAt(bar.length - 1);
        var currbar = `bar${parseInt(curr) - 1}`;
        if(currbar === 'bar0'){
            activeBar('bar5');
        } else {
            activeBar(currbar);
        }
    }

    const nextBar = () => {
        var curr = bar.charAt(bar.length - 1);
        var currbar = `bar${parseInt(curr) + 1}`;
        if(currbar === 'bar6'){
            activeBar('bar1');
        } else {
            activeBar(currbar);
        }
    }
    return (
        <div className="c-main-div">
            <div className="c-main-div1">
                <div className="c-group1-div1">
                    <div className="c-group1-div2">
                        <h5 >Real Estate Fundamentals: Principles and Practices</h5>
                        <h5 style={{ marginLeft: "auto" }}>00:16:31</h5>
                    </div>
                </div>

                <div>
                    <video src={SampleVideo} width="100%" controls />
                </div>

                <div className="c-group2-div1">
                    <div className="c-group2-div2">
                        <div className="c-group2-div3">
                            <button className="c-group2-button1-active" id="bar1" onClick={() => { activeBar('bar1')}}>
                                <span>Course Overview</span>
                            </button>
                            <button id="bar2" className="c-group2-button1" onClick={() => { activeBar('bar2')}}>
                                <span>Discussions</span>
                            </button>
                            <button id="bar3" className="c-group2-button1" onClick={() => { activeBar('bar3')}}>
                                <span>Notes</span>
                            </button>
                            <button id="bar4" className="c-group2-button1" onClick={() => { activeBar('bar4')}}>
                                <span>Announcements</span>
                            </button>
                            <button id="bar5" className="c-group2-button1" onClick={() => { activeBar('bar5')}}>
                                <span>Related Courses</span>
                            </button>
                        </div>
                    </div>

                    <div className="c-group2-div4">
                        <div className="c-group2-div5">
                            <div className="c-group2-div6"> 
                                <button className="c-group2-button2" onClick={() => { previousBar()}}>
                                    <img id="image1" src={PrevImage} alt="" />
                                </button>&nbsp;&nbsp;&nbsp;
                                <span>Previous</span>
                            </div>
                            <div style={{ width: "10%" }}></div>
                            <div className="c-group2-div6"> 
                                <span>Next </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="c-group2-button2" onClick={() => { nextBar()}}>
                                    <img id="image2" src={NextImage} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div style={{ width: "100%" }} id="divbar1">
                    <div className="c-group3-div1">
                        <div className="c-group3-div2">
                            <span className="c-group3-span1">About this course</span>
                            <p className="c-group3-p1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </div>
                    <div className="c-group3-div3">
                        <div className="c-group3-div4">
                            <div className="c-group3-div5">
                                <span className="c-group3-span1">Reviews</span>
                                <p className="c-group3-p1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
        
                            <div className="c-group3-div6">
                                <span className="c-group3-span1">Features</span>
                                <p className="c-group3-p1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                        <div className="c-group3-div7">
                            <div className="c-group3-div8">
                                <div style={{ marginBottom: "10px" }}>
                                    <span className="c-group3-span2">Instructor</span>
                                </div>
                                <div className="c-group3-div9">
                                    <img className="c-group3-img1" src={SampleProfile} alt="..."/>
                                    <span className="c-group3-span3">John Smith</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: "100%" }} id="divbar2" hidden>
                    <div className="c-group4-div1">
                        <div className="c-group4-div2">
                            <h1>Discussions Design Here</h1>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%" }} id="divbar3" hidden>
                    <div className="c-group4-div1">
                        <div className="c-group4-div2">
                            <h1>Notes Design Design Here</h1>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%" }} id="divbar4" hidden>
                    <div className="c-group4-div1">
                        <div className="c-group4-div2">
                            <h1>Announcements Design Here</h1>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%" }} id="divbar5" hidden>
                    <div className="c-group4-div1">
                        <div className="c-group4-div2">
                            <h1>Related Courses Design Here</h1>
                        </div>
                    </div>
                </div>

                <div className="c-group5-div1">
                    <br /><br /><br />
                    <div className="c-group5-div2">
                        <button className="c-group5-button1">SHOW MORE</button>
                    </div>
                </div>
            </div>

            <div className="c-main-div2">
                <div className="c-group6-div1">
                    <div style={{ width: "100%" }}>
                        <div className="c-group6-div2">
                            <span className="c-group6-span1">Course Contents</span>
                            <button id="2" className="c-group6-button1"><img id="image2" src={MenuImage} alt="" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  export default Courses